import { NotificationData, notifications } from '@mantine/notifications';
import dayjs from 'dayjs';

const showNotification = (notification: NotificationData) => {

	notifications.show(notification);
}

const showSavingStart = (id: string, title: string, message: string) => {

	const storageKey = `Notification: ${id}`;

	localStorage.setItem(storageKey, dayjs().toString());

	notifications.show({
		id,
		withCloseButton: true,
		withBorder: true,
		autoClose: false,
		title,
		message,
		color: 'blue',
		loading: true,
	});
}

const showSavingSuccess = (id: string, message: string) => {

	const storageKey = `Notification: ${id}`;
	const timestamp = localStorage.getItem(storageKey);

	if (timestamp) {
		notifications.update({
			id,
			withCloseButton: true,
			withBorder: true,
			autoClose: 3000,
			message,
			color: 'green'
		});
	}
	else {
		notifications.hide(id);
	}

	localStorage.removeItem(storageKey);
};

export {
	showNotification,
	showSavingStart,
	showSavingSuccess
}